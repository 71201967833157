nav {
  background: #fff;
  box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  height: 90px;
  left: 0;
  padding: 0 20px;
  position: fixed;
  top: 0;
  width: calc(100vw - 40px);
  z-index: 999;

  .nav-items-wrapper {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1340px;

    .item-group {
      align-items: center;
      display: flex;
      height: 100%;
      position: relative;
      @media screen and (max-width: 767px) {
        flex: 1;
        height: auto;
      }
    }

    .logo {
      margin-right: 45px;
      z-index: 1000;

      @media screen and (max-width: 767px) {
        margin-right: 20px;
      }

      img {
        height: 50px;
        width: auto;
      }

      &.desktop {
        display: flex;

        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      &.mobile {
        display: none;

        @media screen and (max-width: 767px) {
          display: flex;
        }
      }
    }

    .links-wrapper {
      align-items: center;
      display: flex;
      font-family: "SoraBold";
      height: 100%;

      a {
        color: #11273f;
        font-size: 14px;
        margin: 0 10px;
        text-align: center;
        text-decoration: none;
        transition: 0.3s;
        white-space: nowrap;

        @media screen and (max-width: 991px) {
          font-size: 12px;
        }

        &:hover {
          color: #475b71;
          transition: 0.3s;
        }

        &:first-child {
          margin-left: 20px;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .group {
        align-items: center;
        display: flex;
        height: 100%;
        margin: 0 10px;

        @media screen and (max-width: 767px) {
          height: auto;
        }

        &:last-child {
          margin-right: 0;

          a {
            border-radius: 4px;
            margin: 0 6px;
            padding: 8px 20px;

            &.outline {
              background: #fff;
              border: solid 2px #11273f;
              box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.04);

              &:hover {
                background: #11273f;
                color: rgba(255, 255, 255, 0.84);
              }
            }

            &.full {
              background: #11273f;
              border: solid 2px #11273f;
              box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.14);
              color: rgba(255, 255, 255, 0.84);

              &:hover {
                background: #475b71;
                border: solid 2px #475b71;
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .mobile-menu-toggle {
      display: none;

      @media screen and (max-width: 767px) {
        cursor: pointer;
        display: block;
        height: 100%;
        margin-left: 20px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        width: 18px;

        span {
          background: #11273f;
          border-radius: 1.5px;
          display: block;
          height: 1.5px;
          left: 0;
          opacity: 1;
          position: absolute;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .25s ease-in-out;
          -moz-transition: .25s ease-in-out;
          -o-transition: .25s ease-in-out;
          transition: .25s ease-in-out;
          width: 100%;

          &:nth-child(1) {
            top: calc(50% - 5px);
          }

          &:nth-child(2) {
            top: 50%;
          }

          &:nth-child(3) {
            top: calc(50% + 5px);
          }
        }

        &.menu-open {
          span:nth-child(1) {
            top: 50%;
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            transform: rotate(135deg);
          }

          span:nth-child(2) {
            left: -60px;
            opacity: 0;
          }

          span:nth-child(3) {
            top: 50%;
            -webkit-transform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
            transform: rotate(-135deg);
          }
        }
      }
    }

    .mobile-menu {
      @media screen and (max-width: 767px) {
        align-items: center;
        background: #fff;
        display: flex;
        height: 100vh;
        justify-content: center;
        left: -100vw;
        position: fixed;
        top: 0;
        transition: 0.3s;
        width: 100vw;

        &.show {
          left: 0;
          transition: 0.3s;
        }

        .links-wrapper {
          border-top: 1px solid rgba(207, 217, 221, 0.6);
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 180px;
          width: 100%;

          a, #wallet-connector > div > div > div > a {
            border-bottom: 1px solid rgba(207, 217, 221, 0.6);
            margin: 0 !important;
            padding: 12px 20px;
            text-align: left;
            width: calc(100% - 40px);
          }

          .group {
            display: flex;
            flex-direction: column;
            margin: 0;
            width: 100vw;

            a {
              &.outline,
              &.full {
                background: transparent;
                border: none;
                border-bottom: 1px solid rgba(207, 217, 221, 0.6);
                border-radius: 0;
                box-shadow: none;
                color: #11273f;
                padding: 12px 20px;
                text-align: left;
                width: calc(100% - 40px);

                &:hover {
                  background: inherit;
                  border: none;
                  border-bottom: 1px solid rgba(207, 217, 221, 0.6);
                  color: inherit;
                }
              }
            }
          }
        }
      }
    }
  }
}
